import { defineMessage, t, Trans } from "@lingui/macro"
import GreenCheck from "~/assets/GreenCheck.svg"
import VioletCheck from "~/assets/VioletCheck.svg"
import { i18nCustom } from "~/services/service/i18n-service"
import { Flexed } from "../Flexed"
import { TextWithIcon } from "../Text"

export interface IListProps {
	items: string[]
	color?: "green" | "violet"
	align?: "left" | "center"
}

export function List(props: IListProps) {
	props.items.forEach((item) => {
		defineMessage({ message: item })
	})

	const icon = props.color === "violet" ? VioletCheck : GreenCheck

	return (
		<Flexed direction="column" justify="flex-start" align={props.align === "left" ? "flex-start" : "center"}>
			{props.items.map((item) => (
				<TextWithIcon key={item} centered={props.align === "center"} margin="7px 0" icon={icon} color="#828691">
					{i18nCustom._(item)}
				</TextWithIcon>
			))}
		</Flexed>
	)
}
