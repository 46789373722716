import { defineMessage, t, Trans } from "@lingui/macro"
import { DSubscriptionValue } from "~/services/config/config-types"
import { i18nCustom } from "~/services/service/i18n-service"
import {
	PriceItemBubble,
	PriceItemTitle,
	PriceItem,
	PriceBubble,
	PriceItemLength,
	PriceLength,
	PriceValue,
	PriceOld,
	Crossed,
	PriceSale,
	Price,
} from "../styles"

export interface PriceSelectItemProps {
	trialPeriodDays: number
	amount: number
	oldPrice: number
	pricePerDay: number
	oldPricePerDay: number
	onClick?: () => void
}

const noop = () => void 0

export function PriceSelectItem({
	trialPeriodDays,
	amount,
	oldPrice,
	pricePerDay,
	oldPricePerDay,
	onClick = noop,
}: PriceSelectItemProps) {
	defineMessage({ message: "Days" })
	defineMessage({ message: "Day" })

	return (
		<PriceBubble margin="" padding="8px 0" onClick={onClick}>
			<PriceItem>
				<span>
					<PriceItemLength>
						{trialPeriodDays}-{(trialPeriodDays ?? 0) > 1 ? i18nCustom._("Days") : i18nCustom._("Day")}
						{" trial"}
					</PriceItemLength>
					<PriceOld>
						<Crossed>${(oldPrice ?? 0) / 100}</Crossed> ${((amount ?? 0) / 100).toFixed(2)}
					</PriceOld>
				</span>
				<span style={{ display: "inline" }}>
					<PriceOld>
						<Crossed>${(oldPricePerDay ?? 0) / 100}</Crossed>
					</PriceOld>{" "}
					<PriceValue>${((pricePerDay ?? 0) / 100).toFixed(2)}/day</PriceValue>
				</span>
			</PriceItem>
		</PriceBubble>
	)
}

export function PriceSelectItemHot({
	trialPeriodDays,
	amount,
	oldPrice,
	pricePerDay,
	oldPricePerDay,
	onClick = noop,
}: PriceSelectItemProps) {
	defineMessage({ message: "Days" })
	defineMessage({ message: "Day" })

	return (
		<PriceItemBubble margin="" onClick={onClick}>
			<PriceItemTitle>Most popular 🔥</PriceItemTitle>
			<PriceItem>
				<span>
					<PriceItemLength>
						{trialPeriodDays}-{(trialPeriodDays ?? 0) > 1 ? i18nCustom._("Days") : i18nCustom._("Day")}
						{" trial"}
					</PriceItemLength>
					<PriceOld>
						<Crossed>${(oldPrice ?? 0) / 100}</Crossed> ${((amount ?? 0) / 100).toFixed(2)}
					</PriceOld>
				</span>
				<span style={{ display: "inline" }}>
					<PriceOld>
						<Crossed>${(oldPricePerDay ?? 0) / 100}</Crossed>
					</PriceOld>{" "}
					<PriceValue>${((pricePerDay ?? 0) / 100).toFixed(2)}/day</PriceValue>
				</span>
			</PriceItem>
		</PriceItemBubble>
	)
}
