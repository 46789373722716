import * as server from "../server-api"

export interface RetryInvoiceParams {
	invoiceId: string
	paymentMethodId: string
	fbc?: string
	fbp?: string
}

/**
 */
export const retryInvoice = async ({ invoiceId, paymentMethodId, fbc, fbp }: RetryInvoiceParams) => {
	const domain = process.env.REACT_APP_DOMAIN ?? "course"

	try {
		const { invoice, error } = await server.stripeRetrySubscriptionInvoice({
			invoiceId: invoiceId,
			paymentMethodId: paymentMethodId,
			fbc,
			fbp,
			domain,
		})

		if (error) {
			const msg = typeof error === "string" ? error : error.message ?? "Card was not attached to a customer."
			throw new Error(msg)
		}

		return invoice
	} catch (ex) {
		console.error(ex)
		throw ex
	}
}
