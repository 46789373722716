export enum DDomain {
	Course = "course",
	TestCourse = "test.course",
	Yoga = "yoga",
}

export enum DLandingType {
	Alpha = "landing-alpha",
	Bravo = "landing-bravo",
	Charlie = "landing-charlie",
	Delta = "landing-delta",
	Echo = "landing-echo",
}

export enum DPrepaywallType {
	Alpha = "prepaywall-alpha",
	Bravo = "prepaywall-bravo",
}

export enum DPaywallType {
	Alpha = "paywall-alpha",
	Bravo = "paywall-bravo",
	Charlie = "paywall-charlie",
	Delta = "paywall-delta",
	Echo = "paywall-echo",
	Foxtrot = "paywall-foxtrot",
	Hotel = "paywall-hotel", // TODO:  у нас нет HOTEL  !!!!!!!
	India = "paywall-india",
	India3prices = "paywall-india-3-prices",
	Juliett = "paywall-juliett",
}

export type DOnboardingType = `onboarding-${string}`

export interface SubscriptionValueLabel {
	description: string
	button: string
}

export interface DSubscriptionValue {
	id: string
	isPopular: boolean
	paddleProductId: number
	priceId: string
	planId: string
	oldPrice: number
	oldPricePerDay: number
	pricePerDay: number
	trial: {
		amount: number
		currency: string
		trialPeriodDays: number
	}
	labels: SubscriptionValueLabel
	locales: Record<string, SubscriptionValueLabel>
}

export type DPaywallFirstScreenValue = "no" | "yes"

export interface IDConfig {
	webLandingType: DLandingType
	webOnboardingType: DOnboardingType
	webOnboardingTypeMan: DOnboardingType
	webPrepaywallType: DPrepaywallType
	webPaywallType: DPaywallType
	/**
	 * @deprecated use webSubscriptionSelect
	 */
	webSubscription: DSubscriptionValue
	webSubscriptionSelect: DSubscriptionValue[]
	paywallFirstScreen: DPaywallFirstScreenValue
	webSubscriptionDefault: string
}

export interface IDConfigService {
	fetchConfig(langCode?: string): Promise<IDConfig>
}
