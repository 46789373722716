import { IConfigDTO } from "./config-dto-types"

export const mockedConfig = {
	webLandingType: [
		{
			domain: "course",
			value: [
				{
					sortIndex: 0,
					value: "landing-delta",
				},
			],
		},
	],
	webOnboardingType: [
		{
			domain: "course",
			value: [
				{
					sortIndex: 0,
					value: "onboarding-juliet",
				},
			],
		},
	],
	webOnboardingTypeMan: [
		{
			domain: "course",
			value: [
				{
					sortIndex: 0,
					value: "onboarding-men",
				},
			],
		},
	],
	webPrepaywallType: [
		{
			domain: "course",
			value: [
				{
					sortIndex: 0,
					value: "prepaywall-alpha",
				},
			],
		},
	],
	webPaywallType: [
		{
			domain: "course",
			value: [
				{
					sortIndex: 0,
					//value: "paywall-juliett",
					value: "paywall-india-3-prices",
				},
			],
		},
	],
	webSubscription: [],
	webSubscriptionSelect: [
		{
			domain: "course",
			value: [
				{
					value: [
						{
							id: "1$ trial and 19.99$ after",
							isPopular: false,
							paddleProductId: 0,
							priceId: "price_1MUxB1FHfJrkKA965VfJ9Pb7",
							planId: "P-51K29571YB508222FMPVTDTY",
							oldPrice: 1999,
							pricePerDay: 99,
							oldPricePerDay: 142,
							trial: {
								amount: 100,
								currency: "usd",
								trialPeriodDays: 3,
							},
							labels: {
								description: "pora will automatically continue your membership",
								button: "Start Pora Course",
							},
							locales: {},
						},
						{
							id: "5$ trial and 19.99$ after",
							isPopular: true,
							paddleProductId: 0,
							priceId: "price_1MUxB1FHfJrkKA965VfJ9Pb7",
							planId: "P-2CT53732DJ264801LMPVTD5A",
							oldPrice: 1999,
							oldPricePerDay: 142,
							pricePerDay: 99,
							trial: {
								amount: 500,
								currency: "usd",
								trialPeriodDays: 3,
							},
							labels: {
								description: "pora will automatically continue your membership",
								button: "Start Pora Course",
							},
							locales: {},
						},
						{
							id: "11.41$ trial and 19.99$ after",
							isPopular: false,
							paddleProductId: 0,
							priceId: "price_1MUxB1FHfJrkKA965VfJ9Pb7",
							planId: "P-0TG51145VY736330PMPVTEFY",
							oldPrice: 1999,
							oldPricePerDay: 142,
							pricePerDay: 99,
							trial: {
								amount: 1141,
								currency: "usd",
								trialPeriodDays: 3,
							},
							labels: {
								description: "pora will automatically continue your membership",
								button: "Start Pora Course",
							},
							locales: {},
						},
					],
					sortIndex: 0,
				},
			],
		},
		{
			domain: "test.course",
			value: [
				{
					value: [
						{
							id: "1$ trial and 19.99$ after",
							isPopular: false,
							paddleProductId: 0,
							priceId: "price_1MPKRqFHfJrkKA962WQWwFSO",
							planId: "P-1X660892XY061834CMQA5JTQ",
							oldPrice: 1999,
							pricePerDay: 99,
							oldPricePerDay: 142,
							trial: {
								amount: 100,
								currency: "usd",
								trialPeriodDays: 3,
							},
							labels: {
								description: "pora will automatically continue your membership",
								button: "Start TEST Pora Course",
							},
							locales: {},
						},
						{
							id: "5$ trial and 19.99$ after",
							isPopular: true,
							paddleProductId: 0,
							priceId: "price_1MPKRqFHfJrkKA962WQWwFSO",
							planId: "P-0JH72671A7862905CMQA5J6A",
							oldPrice: 1999,
							oldPricePerDay: 142,
							pricePerDay: 99,
							trial: {
								amount: 500,
								currency: "usd",
								trialPeriodDays: 3,
							},
							labels: {
								description: "pora will automatically continue your membership",
								button: "Start TEST Pora Course",
							},
							locales: {},
						},
						{
							id: "11.41$ trial and 19.99$ after",
							isPopular: false,
							paddleProductId: 0,
							priceId: "price_1MPKRqFHfJrkKA962WQWwFSO",
							planId: "P-9WU59029HT7093422MQA5KSA",
							oldPrice: 1999,
							oldPricePerDay: 142,
							pricePerDay: 99,
							trial: {
								amount: 1141,
								currency: "usd",
								trialPeriodDays: 3,
							},
							labels: {
								description: "pora will automatically continue your membership",
								button: "Start TEST Pora Course",
							},
							locales: {},
						},
					],
					sortIndex: 0,
				},
			],
		},
	],
	webAbTest: [
		{
			domain: "course",
			value: [
				{
					active: "no",
					param: "webOnboardingType",
					variants: {
						A: {
							sortIndex: 0,
							value: "50%",
						},
						B: {
							sortIndex: 100,
							value: "50%",
						},
					},
				},
			],
		},
	],
	paywallFirstScreen: [
		{
			domain: "course",
			value: [
				{
					sortIndex: 0,
					value: "no",
				},
			],
		},
	],
} as IConfigDTO
